
.separated-at-bottom {

    &:after {
        content: " ";
        border-top-width: 1px;
        border-top-style: solid;
        display: block;
        height: 1px;
        margin: 4rem auto;
        width: 100%;
    }
}
