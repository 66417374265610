.rotates-image {
    background: #075ecf;
    margin: 0;
    overflow: hidden;

    img {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -1000px;
        margin-left: -1000px;
        height: 2000px;
        width: 2000px;
        transition: transform 1s linear;
        transform-style: preserve-3D;
        opacity: .5;
        animation: rotate-world 280s infinite;
    }
}

@keyframes rotate-world  {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(359deg);
    }
}
