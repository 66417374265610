
$text-font-stack:    medium-content-sans-serif-font,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Open Sans","Helvetica Neue",sans-serif;
$title-font-stack:   'Playfair Display', times, serif;
$small-title-font-stack:   Arial, Helvetica, sans-serif;

html, body {
    background:  #fefefe;
    color: #091527;
    font: normal 20px $text-font-stack;
    letter-spacing: 0;
    font-weight: 400;
    font-style: normal;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    line-height: 1.8;
    margin: 0;
    min-height: 100%;
}

@media all and (max-width: 500px) {
    html, body {
        font-size: 18px;
    }
}


/* TOOLSET */
/* ------- */

h1, h2, h3, h4 {
    font-family: $title-font-stack;
}

ul li {
    margin: 1rem 1rem 1rem 0;
}

time {
    display: block;
    font-family: $small-title-font-stack;
    text-transform: uppercase;
    font-size: .55rem;
    margin-bottom: 1rem;
}

.muted {
    color:#909aa8;
}

.lead {
    font-size: 1.3rem;
}

a {
    color: #34659e;
    text-decoration: none;
    border-bottom: 1px solid;
}

a:hover {
    color: #1e83ff;
}

article {
    color: #6e747c;

    main {
        h1, h2, h3, h4 {
            color: #1a4072;
        }
    }
}

table {
    margin: auto;
    width: 100%;
    margin: 0.5rem;
    border: 1px solid #909aa8;
    border-collapse: collapse;

    thead {
        background: #1a4072;
        color: #fff;
    }

    td, th {
        border-bottom: 1px solid #909aa8;
        padding: 0.5rem;
        text-align: left;
    }
}

.separated-at-bottom:after {
    border-top-color: #bbc7da;
}

.inverted-colors {
    color: #fefefe;
    a {
        color: #fefefe;
    }
    .separated-at-bottom:after {
        border-top-color: #f8fbff;
    }
    .muted, time {
        color:#fafafa;
    }
}

footer {
    font-size: 12px;
    color: #091527;
}

pre {
    font-size: .9rem;
}

code {
    font-weight: bold;
}

.centered {
    text-align:center;
}

.text-aligned-right {
    text-align: right;
}

.pinned {
    position: absolute;
    top: 0;
}

.stretched {
    height: 100%;
    width: 100%;
}

.vertically-centered {
    display: flex;
    align-items: center;
}

.horizontally-aligned-children {
    display:flex;
}

.spaced-evenly {
    justify-content: space-between;
}

.relative {
    position: relative;
}

.half-screen-height {
    height: 50vh;
    min-height: 500px;
}

.some-screen-height {
    min-height: 33vh;
}

.bulletless {
    list-style: none;
}

.content-column {
    padding: 2.3rem;
    margin: 0 auto;
    max-width: 740px;
    width: 100%;
}

.padded-bottom {
    margin-bottom: 3rem
}

.tight {
    margin: 0;
    padding: 0;
}

.tight-line {
    line-height: 1;
}

.biggest {
    font-size: 2.4rem;
}

.bigger {
    font-size: 2.2rem;
}

.borderless {
    border: none;
}

.icon {
    display: inline-block;
    height: 1em;
    width: 1em;
}

.to-right {
    justify-content: flex-end;
}

.split-children-in-thirds {
    > * {
        margin-left: 1.5%;
        width: 30%;

        &:first-child {
            margin-left: 0;
        }
    }
}

.split-children-in-halves {
    > * {
        margin-left: 1%;
        width: 49%;

        &:first-child {
            margin-left: 0;
        }
    }
}

.dash {
    border-top: 2px solid #f8fbff;
    border-bottom: 1px solid #f8fbff;
    display: block;
    height: 1px;
    margin: auto;
    width: 100px;
}


img {
    display: block;
    margin: auto;
    max-width: 100%;

    & + em {
        display: block;
        font-size: .7rem;
        text-align: center;
    }
}

iframe {
    border: 2px solid #96a4b9;
    display: block;
    margin: auto;
    width: 560px;
    height: 315px;
    max-width: 100%;
}

p {
    line-height: 1.9;

    a {
        border-bottom: 1px solid;
        font-weight: bold;
        margin: 0 0.22em;
    }

    em {
        margin-right: 0.22em;
    }
}

blockquote {
    color: #28518f;
    border-left: 2px solid #28518f;
    padding-left: 1.5rem;
}

p, .highlight, blockquote, iframe {
    margin-bottom: 1.8rem;
}

figure {
    background: #fafafa;
    border: 1px solid #ddd;
    margin: 0;
}

.highlight {
    border-radius: .25rem;
    background: #091527;
    color: #c5c8c6;
}

.placeholder-value {
    background: rgb(189, 202, 214);
    border-radius: .25rem;
    color: #091527;
    font-style: normal;
    padding: 0.1rem 0.25rem;
    font-weight: normal;
}

pre {
    overflow: auto;
    padding: 1rem;
    margin: 0;
    code {
        font-weight: normal;
        line-height: 1.25;
        font-size: .85rem;
    }
}

.higher-bg-layer {
    background: #fefefe;
    position: relative;
    z-index: 3;
    padding: 0 0 1px 0;
}
