header {
    .linear-mask {
        background: linear-gradient(transparent, #091527);
    }

    .radial-mask {
        background: radial-gradient(transparent, #091527);
    }
}

.article-header {
    background: linear-gradient(#0b1d35, #0d213b);
    border-bottom: 1px solid #075ecf;
    color: #f8fbff;

    h1, h2, .author, .dash {
        margin-bottom: 2rem;
    }

    h1 {
        font-size: 2.2rem;
        line-height: 1.2;
    }

    h2 {
        font-size: 1.8rem;
        font-family: $text-font-stack;
    }
}

.article-navigation {
    color :#909aa8;
    border-bottom: 1px solid #96a4b9;
    font-size: .9rem;
    list-style: none;
    padding: 0 2rem 2rem;
    margin: 0 0 2rem 0;

    span {
        display: inline-block;
        font-weight: bold;
        width: 2rem;
        text-align: center;
    }
}
